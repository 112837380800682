.header-menu a{
 font-weight: 400;
 font-size: 20px;
 color: #000;
 text-decoration: none;
 text-transform: uppercase;
 cursor: pointer;
}
.header-logo img{
    max-width: 27%;
}
/* .header-menu {
    padding-top: 33px;
} */
.download-btn{
    background-color: #439ABA !important; 
    border-color: #439ABA !important;
    font-weight: 500 !important;
    font-size: 18px !important;
    border-radius: 50px !important;
    padding: 7px 30px !important;
}
.joinus-banner {
    background-image: url(../assests/images/banner_bg.png);
    background-repeat: no-repeat;
    background-position:100% -22%;
    background-size: 50%;
}
.joinus-padding{
    padding-bottom: 200px;
}
.joinus-banner .joinus-content h3{ 
    font-weight: 800;
    font-size: 72px;
}
.joinus-banner .joinus-content h3 span{
    color: #439ABA !important;
}
.img-hand-background{
    position: relative;
    height: 100%;
}
section.joinus-banner {
    padding-top: 20px;
}
.img-hand-background::after{
    position: absolute;
    border-radius: 350px 350px 0px 0px;
    background-color: rgba(81, 184, 222, 0.2);
    content: '';
    left: 0px;
    right: 0px;
    height: 700px;
    bottom: 0px;
}
.img-hand {
    position: absolute;
    bottom: -131px;
    right: auto;
    left: calc(50% - 174px);
    z-index: 99;
    width: 793px !important;
    max-width: 605px !important;
}
.joinus-banner .joinus-content p{
    color: #585858 !important;
    font-size: 24px;
    font-weight: 300;
}

.joinus-banner .joinus-content-downloaded-content h3{
   font-size: 70px;
   font-weight: 800;
   columns: #000;
}
.joinus-banner .joinus-content-downloaded-content span{
    font-weight: 300;
    font-size: 20px;
    color: #585858;
}

.how-its-work{
    background-color: #439ABA;
    padding: 157px 0;
    z-index: 1111;
    position: relative;
    overflow: hidden;
}
.how-its-work .how-it-work-content h4{
    font-weight: 800;
    font-size: 70px;
    color: #fff;
}
.how-it-work-content .row{
    margin-bottom: 50px;
}
.how-it-work-content .icon-style{
    width: 80px;
    height: 80px;
    object-fit: contain;
}
.how-it-work-content .icon-content h6{
    font-weight: 800;
    font-size: 29px;
    color: #fff;
    margin-bottom: 8px;
    line-height: 29px;
}
.how-it-work-content .icon-content span{
    font-weight: 400;
    font-size: 18px;
    color: #F2F2F2;
    line-height: 12px;
}   

.user-friendly-content h3{
    font-weight: 800;
    font-size: 53px;
}
.user-friendly-content h3 span{
    color: #0A86C2 !important;
}
.user-friendly-interface{
    padding: 7% 0;
    overflow: hidden;
}
.user-friendly-content p{
    font-weight: 400;
    font-size: 20px;
}
.user-friendly-img-2{
    position: relative;
    right: 32px;
}
.user-friendly-img{
    position: relative;
    left: 33%;
    top: 28px;
}
.footer{
    background-image: url("../assests/images/footer-bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 170vh;
    padding: 7%;
    position: relative;
}
.copy_right_text {
    position: absolute;
    bottom: 10px;
    z-index: 9999;
    left: 50%;
    transform: translate(-50%, 10px);
    color: #02374A;
    font-size: 20px;
}
.footer .footer-left-portion img{
    max-width: 100%;
}
.footer .footer-left-portion  a{
    display: inline-block;
    width: 200px;
}
.footer .footer-left-portion h4{
    color: #02374A !important;
    font-weight: 800;
    font-size: 59px;
}

.footer .footer-left-portion .address-detail p{
    border: 1px solid #439ABA;
    background-color: #fff;
    color:  #439ABA;
    border-radius: 5px;
    padding: 3px 7px;
    margin-right: 10px;
    width: 50px;
    height: 50px;
    margin-bottom: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.footer .footer-left-portion .address-detail p svg{
    width: 22px;
}
.footer .footer-left-portion .address-detail span{
    font-weight: 400;
    font-size: 20px;
    color: #000;
    flex: 1;
}
.footer .footer-right-portion{
    box-shadow: rgba(0, 0, 0, 0.05);
    background-color: #fff;
    padding: 35px;
    border-radius: 20px;
}
.footer .footer-right-portion h4{
    font-weight: 800;
    font-size: 60px;
    color: #000;
}
.address-detail{
    align-items: center;
    margin-bottom: 14px;
}
.footer .footer-right-portion .input-label{
    position: absolute;
    top: -13px;
    left: 20px;
    color: #727272;
    font-weight: 300;
    font-size: 18px;
    background-color: white;
    z-index: 99;
}
.footer-contact-us-submit-btn{
    border-radius: 5px !important;
}
.ai-chat-bot{
    background-color: #02374A;
    margin-top: 15%;
    padding-bottom: 37px;
}
.ai-chat-bot .ai-chat-bot-img{
    width: 20%;
}

.ai-chat-bot .ai-group-img1{
    position: relative;
    left:13%;
    top: 8px;
}
.ai-chat-bot .ai-group-img1 img{
    max-width: 92%;
} 
.ai-chat-bot .ai-group-img2{
    position: relative;
    left: 6%;
}
.ai-chat-bot .ai-group-img3{
    position: relative;
    right: 6%;
    z-index: 1;
}
.ai-chat-bot .ai-group-img4{
    position: relative;
    right: 10%;
    top: 15px;
}
.ai-chat-bot .ai-group-img4 img{
    max-width: 92%;
} 
.ai-chat-bot .ai-group-img5{
   z-index: 99;
}
.ai-slider h3{
   font-weight: 800;
   font-size: 60px;
   overflow-y: hidden;
}
.img-group-ai {
    position: relative;
    top: -235px;
    z-index: 9999;
    max-width: 1720px;
    margin: auto;
}
.ai-bot-content-left-portion h3{
    font-weight: 800;
    font-size: 55px;
    color: #fff;
}
.ai-bot-content-left-portion h3 span{
    color: #1FC4E0;
}
.ai-bot-content-left-portion p{
    font-weight: 400;
    font-size: 17px;
    color: #fff;
}
.ai-bot{
    max-width: 30%;
    position: absolute;
    top: 84px;
    right: 74px;
    animation: moveUpDown 1s infinite alternate;
}

.span_line_contact {
    display: inline-block;
    width: 15px;
    height: 70px;
    border-radius: 20px;
    background-color: #439ABA;
    position: absolute;
    left: -6px;
}
.header-menu {
    gap: 48px;
}
@keyframes moveUpDown {
    0% {
      transform: translateY(0); /* Start at current position */
    }
    100% {
      transform: translateY(-50px); /* Move up by 50 pixels */
    }
  }


  /* text slider */
@-webkit-keyframes slide{0%{-webkit-transform:translate(0,0);-ms-transform:translate(0,0);transform:translate(0,0);}100%{-webkit-transform:translate(100%,0);-ms-transform:translate(100%,0);transform:translate(100%,0);}}/*!sc*/
@keyframes slide{0%{-webkit-transform:translate(0,0);-ms-transform:translate(0,0);transform:translate(0,0);}100%{-webkit-transform:translate(100%,0);-ms-transform:translate(100%,0);transform:translate(100%,0);}}/*!sc*/
.marquee-cont{
    position: relative;
      width: 100%;
      height: 100%;
      overflow: hidden;
      display: flex;
      flex-flow: row nowrap;
      -webkit-box-pack: center;
      justify-content: center;
    font-family: 'Archivo', sans-serif;
}
  
.marquee-slider{
        min-width: 100%;
      flex-shrink: 0;
        animation-name: slide;
      animation-direction: reverse;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
      animation-play-state: running;
    animation-duration: 10s;
}
  
.marquee-slider p {
    display: inline-block;
    position: relative;
    font-size: 500px;
    margin: 0;
    padding: 20px;
}
  

.bold{
    font-weight: 800; 
    font-size: 60px;
}
.med{
    font-weight: 600;
}
.lit{
    font-weight: 300;
}

/* ai circle transition */
.cirecle-transition{
    left: 1px;
    bottom: 190px;
}
.ai-bot-mobile-img{
    position: relative;
}
@keyframes expand-contract {
    0%, 100% {
      r: 5;
      opacity: 1;
    }
    50% {
      r: 110;
      opacity: 0;
    }
  }

  .circle1 {
    fill: black;
    opacity: 0.8;
    animation: expand-contract 6s infinite;
  }

  .circle2 {
    fill: black;
    opacity: 0.6;
    animation: expand-contract 6s infinite 1s;
  }

  .circle3 {
    fill: black;
    opacity: 0.4;
    animation: expand-contract 6s infinite 2s;
  }

  svg {
    width: 100%;
    height: 100%;
    display: block;
  }
  .marque_line.position-relative marquee svg {
    height: 512px;
    width: 4702px !important;
}
.marque_line.position-relative {
    margin-top: -497px;
    z-index: 1;
}
.whitespace-nowrap{
    white-space: nowrap;
    display: block;
}

.marque_line  marquee.bold {
    font-size: 500px;
    position: relative;
    color: #115066;
}

.marque_line marquee.bold span {
    position: absolute;
    color: transparent;
    left: 5px;
    top: -11px;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #fff;
    /* z-index: -1; */
}
.form-control {
    padding: 17px !important;
}
section.how-it-works {
    background-color: #439ABA;
    padding: 100px 0;
    position: relative;
    z-index: 555;
}
.how-it-works{
    color: #fff;
}
.steps .step {
    display: flex;
    margin-bottom: 50px;
    column-gap: 20px;
    align-items: center;
}
section.how-it-works h2 {
    font-size: 50px;
    font-weight: bold;
    margin-bottom: 50px;
}
.right-sec p {
    font-size: 24px;
    font-weight: 800;
    margin-bottom: 0;
}

@media (min-width:1440px) {
    .container{
      max-width: 1420px !important;
    }
}
@media (min-width:1920px) {
  .container{
    max-width: 1720px !important;
  }
  .ai-bot-content-left-portion h3{
    font-size: 85px !important;
  }
  .joinus-banner {
   padding-top: 70px;
  }
  img.img-fluid.ai-bot-mobile-img{
    height: 100% !important;
    margin-left: auto;
        display: block;
  }
  .ai-bot {
    right: -67px !important;
}
.img-hand-background::after{
    height: 600px;
}
.img-hand {
    position: absolute;
    bottom: -135px;
    right: auto;
    left: calc(50% - 188px);
    z-index: 99;
    width: 793px !important;
    max-width: 631px !important;
}
.img-hand-background::after{
    height: 700px;
}
section.joinus-banner {
    padding-top: 100px;
}
}



@media only screen and (max-width:1400px){
    .joinus-banner .joinus-content-downloaded-content h3{
        font-size: 52px;
    }
}
@media only screen and (min-width: 1200px) and (max-width: 1366px)  {
  .container{
    max-width: 1250px !important;
  }
  .user-friendly-interface,.joinus-banner{
    overflow: hidden;
  }
  .joinus-banner .joinus-content h3{
    font-size: 58px ;
  }
  .header-menu a{
    font-size: 16px;
  }
  img.img-fluid.ai-bot-mobile-img{
    margin-left: 50px;
  }
  .ai-bot{
    right: 25px;
  }
  /* .img-hand-background::after{
    height: 450px;
  } */
  .how-it-work-content .row{
    margin-bottom: 20px;
}
}

@media (max-width:1200px) {
    
}
@media (max-width:992px) {
    .img-hand{
        position: static;
        width: 100% !important;
        max-width: 100% !important;
    }
    .footer{
        height: 212vh;
    }
    .img-hand-background::after{
        height: 599px;
    }
    .joinus-padding{
        padding-bottom: 50px;
    }
    section.joinus-banner {
        padding-top: 0;
    }
}
@media (max-width:767px) {
    .footer-right-portion .row .col {
        width: 100% !important;
        flex-basis: 100%;
    }
    .joinus-banner .joinus-content h3{
        font-size: 45px;
    }
    .img-hand-background {
        overflow: hidden;
        height: 435px;
        display: flex;
        justify-content: center;
        align-items: end;
        margin-top: 35px;
    }
    .img-hand {
        max-width: 93% !important;
        /* margin: auto; */
        /* display: block; */
        margin-bottom: -62px;
        position: relative;
        bottom: -10px;
        left: 13%;
    }
      .img-hand-background::after {
        height: 430px;
    }
    .ai-bot-content-left-portion h3 span{
        white-space: normal;
    }
    .ai-bot-content-left-portion h3 {
        line-height: 45px;
    }
    .ai-bot-content-left-portion h3 span{
        font-size: 45px;
        display: inline;
    }
    .footer .footer-left-portion h4 {     
        font-size: 40px;
    }
    .card_how_it_works{
        justify-content: center;
        gap: 20px;
    }
    .user-friendly-content h3 {
        font-size: 35px !important;
    }
    .img-group-ai {
        top: -85px;
    }
    .marque_line.position-relative{
        margin-top: -200px;
    }
    header{
        border-bottom: 1px solid #ccc;
    }
    .navbar-brand img {
        max-width: 135px;
    }
    .header-menu {
        gap: 18px;
    }
    .how-its-work {
        padding: 70px 0;
    }
    .how-its-work .how-it-work-content h4{
        font-size: 40px;
    }
    .footer .footer-right-portion {
        padding: 15px;
        margin-top:35px;
    }
    .footer .footer-right-portion h4{
        font-size: 40px !important;
        margin-bottom: 20px !important;
    }
    footer {
        padding: 7% 15px !important;
        height: 180vh !important;
    }
    section.joinus-banner,.how-its-work,.user-friendly-interface,footer,header{
        overflow: hidden;
    }
    .copy_right_text {
        font-size: 15px;
        width: 100%;
    }
    marquee {
        height: 300px;
        display: flex;
        align-items: center;
    }
    .marque_line.position-relative marquee svg{
        transform: scale(0.3);
    }
    section.joinus-banner > .container .row.mt-5 {
        margin-top: 0px !important;
    }
    .ai-chat-bot .ai-group-img4{
        top:10px;
    }
    section.joinus-banner .row.mt-5 .col-lg-5 {
        order: 2;
    }
    .joinus-content h3.w-100.mt-5 {
        margin-top: 0px !important;
    }
    .how-it-work-content .icon-content h6{
        font-size: 22px;
    }
    .joinus-banner .joinus-content-downloaded-content h3{
        font-size: 46px;
    }
}



@media only screen and (max-width:575px){
    .footer .footer-left-portion .address-detail span{
        font-size: 14px;
    }
    section.how-it-works{
        padding: 50px 0 60px;
    }
}
.address-detail .social-link svg {
    width: 25px;
    fill: #02374a;
}

.address-detail .social-link {
    width: auto !important;
}
.user-friendly-content h3:first-child {
    color: #439ABA !important;
    padding-bottom: 18px;
    font-size: 84px;
}