@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

body{
  font-family: "Inter", sans-serif !important;
}

.App {
  text-align: center;
}
.header-menu a:hover {
  color: #439aba;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.download-btn:hover{
  background-color: #000 !important; 
}
.footer-contact-us-submit-btn {
  height: 50px;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 768px) {
  .aos-animate {

    -o-transition-property: none !important;
    -moz-transition-property: none !important;
    -ms-transition-property: none !important;
    -webkit-transition-property: none !important;
    transition-property: none !important;

    -o-transform: none !important;
    -moz-transform: none !important;
    -ms-transform: none !important;
    -webkit-transform: none !important;
    transform: none !important;

    -webkit-animation: none !important;
    -moz-animation: none !important;
    -o-animation: none !important;
    -ms-animation: none !important;
    animation: none !important;
  }
}
/* [data-aos^=fade][data-aos^=fade]{
  opacity: 1 !important;
} */
 /* Privacy Policy Section */
.privacy-policy-section {
  padding: 2rem 0; /* Adjust padding as needed */
}

.privacy-policy-content {
  text-align: center; /* Center align text */
}

.privacy-policy-section h2 {
  margin-bottom: 1.5rem; /* Space between heading and content */
}

/* Header Spacing */
header {
  margin-bottom: 2rem; /* Adjust the spacing below the header */
}

/* Terms and Conditions Section */
.terms-conditions-section {
  padding: 2rem 0; /* Adjust padding as needed */
}

.terms-conditions-content {
  text-align: center; /* Center align text */
}

.terms-conditions-section h2 {
  margin-bottom: 1.5rem; /* Space between heading and content */
}

/* Header Spacing */
header {
  margin-bottom: 2rem; /* Adjust the spacing below the header */
}
.how-img img {
  width: 100%;
  max-height: 600px;
  object-fit: contain;
}
.copy_right_text a {
  color: #565656;
  text-decoration: none;
}


